import { Controller } from "@hotwired/stimulus"
import { chart } from "../utils/apexChart"

export default class extends Controller {
  static targets = ['chart', 'outdoor', 'legendContainer', 'legendTemplate']

  connect() {
    if (this.chart) { return }

    if (this.hasChartTarget) {
      const {dataset} = this.chartTarget

      this.chart = chart(this.chartTarget, dataset)
      this.chart.render().then(() => this.dispatch('ready'))
    }
  }

  update(event) {
    event.stopPropagation()
    const {dataset} = this.chartTarget

    this.chart = chart(this.chartTarget, dataset)
    this.chart.render().then(() => this.dispatch('ready'))
  }

  addOutdoor() {
    const { series } = this.outdoorTarget.dataset
    this.chart.appendSeries(JSON.parse(series))
  }

  removeOutdoor({ detail }) {
    const { serieName } = detail
    this.chart.hideSeries(serieName)
  }

  displayOutdoor({ detail }) {
    const { serieName } = detail
    this.chart.showSeries(serieName)
  }

  updateSeries(event) {
    event.stopPropagation()
    const series = event.detail?.series || event.params.series
    this.chart.updateSeries(series)
  }

  updateOptions(event) {
    event.stopPropagation()

    const labels = event.detail.labels || event.params.labels
    const series = event.detail.series || event.params.series
    this.chart.updateOptions({series, labels})

    if (event.detail.colors) {
      this.chart.updateOptions({colors: JSON.parse(event.detail.colors)})
    }

    if (typeof(event.detail.grades) != "undefined") {
      const grades = JSON.parse(event.detail.grades)
      if (this.canUpdateLegends(grades)) {
        this.updateLegends(labels, grades)
      }
    }
  }

  updateHeatmapOptions(event) {
    event.stopPropagation()

    this.chart.updateOptions({plotOptions: { heatmap: { colorScale: JSON.parse(this.chartTarget.dataset.ranges)}}})
  }

  canUpdateLegends(grades) {
    return this.hasLegendContainerTarget && this.hasLegendTemplateTarget && !!grades?.length
  }

  updateLegends(labels, grades) {
    this.legendContainerTarget.innerHTML = ''

    grades.map((grade, index) => {
      const legend = this.legendTemplateTarget.content.cloneNode(true).childNodes[0]
      const label = document.createElement('span')
      label.innerHTML = labels[index]

      legend.childNodes[0].classList.add(grade)
      legend.appendChild(label)
      this.legendContainerTarget.appendChild(legend)
    })
  }
}
