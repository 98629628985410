import { Controller } from "@hotwired/stimulus"
import Leaflet from 'leaflet'
import 'leaflet.markercluster'

export default class extends Controller {
  static targets = ['map']

  connect() {
    try {
      this.places = JSON.parse(this.mapTarget.dataset.places) || []
      this.bounds = this.places
        .filter(place => Array.isArray(place.coordinates) && place.coordinates.length === 2)
        .map(place => place.coordinates)

      if (this.bounds.length > 0) {
        this.initMap()
      }
    } catch (error) {
      console.error('Failed to initialize map:', error)
    }
  }

  initMap() {
    this.setupBaseMap()
    this.setupTileLayers()
    this.setupMarkerCluster()
    this.addMarkers()
    this.map.addLayer(this.markersLayout)
  }

  setupBaseMap() {
    this.map = Leaflet.map(this.mapTarget).fitBounds(this.bounds, {maxZoom: 13})
    this.map.createPane('labels')
  }

  setupTileLayers() {
    Leaflet.tileLayer(
      'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
      {attribution: '©OpenStreetMap, ©CartoDB'}
    ).addTo(this.map)

    Leaflet.tileLayer(
      'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
      {pane: 'labels'}
    ).addTo(this.map)
  }

  setupMarkerCluster() {
    this.markersLayout = Leaflet.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 10,
      iconCreateFunction: (cluster) => {
        return Leaflet.divIcon({
          html: `<div><span>${cluster.getChildCount()}</span></div>`,
          className: 'marker-cluster'
        })
      }
    })

    this.markersLayout.on('clusterclick', (event) => {
      event.layer.zoomToBounds({padding: [20, 20]})
    })
  }

  addMarkers() {
    this.places.forEach(place => {
      const marker = Leaflet.marker(place.coordinates, {
        icon: this.createIcon(place)
      })

      marker.bindPopup(this.createPopupContent(place))

      marker.on('mouseover', function() { this.openPopup() })
      marker.on('mouseout', function() { this.closePopup() })

      this.markersLayout.addLayer(marker)
    })
  }

  createPopupContent(place) {
    const div = document.createElement('p')
    div.className = 'marker-tooltip'
    div.textContent = place.name
    return div
  }

  createIcon({ grade, id, type, url }) {
    const className = `${type === 'establishment' ? 'marker-icon' : 'sensor-marker-icon'} ${grade} is-active`
    const element = type === 'establishment'
      ? `<div class='${className}' data-id='${id}' data-type='${type}'></div>`
      : `<a class='${className}' data-id='${id}' data-type='${type}' href='${url}'></a>`

    return Leaflet.divIcon({
      html: element,
      className: 'marker-icon-container',
      iconAnchor: [15, 15]
    })
  }
}