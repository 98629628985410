import { Controller } from "@hotwired/stimulus"
import { close, showV2, hideV2, isHiddenV2, activate, desactivate, isActivated } from '../utils/display'
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [
    'select',
    'selected',
    'dropdown',
    'options',
    'option',
    'clearButton',
    'selectedContainer',
    'template'
  ]
  static values = { selectedOptions: Array }

  connect() {
    useClickOutside(this)

    let value = []
    this.multiple = this.selectTarget.multiple

    if (this.multiple) {
      if (this.selectedOptionsValue.length !== 0) {
        for (const option of this.selectedOptionsValue) {
            value.push({id: option[1], name: option[0]})
            this.toggleTag(option[1], option[0])
        }
      } else {
        for (const option of this.selectTarget.options) {
          if (option.selected && option.value !== '') {
            value.push({id: option.value, name: option.label})
            this.toggleTag(option.value, option.label)
          }
        }
      }

      value.map(val => val.id).forEach(id => {
        activate([this.optionTargets.filter(option => option.dataset.searchableSelectValueParam == id)[0]])
      })
    } else {
      const selectedOption = this.selectTarget.selectedOptions[0]
      if (selectedOption && selectedOption.value !== '') {
        value = selectedOption.value
        this.selectedTarget.textContent = selectedOption.label
        this.selectedTarget.classList.remove('placeholder')
        activate([this.optionTargets.filter(item => item.dataset.searchableSelectValueParam == value)[0]])
      }
    }

    if (value.length && this.element.dataset.dispatch === 'true') {
      this.dispatch("initField", { detail: { field: this.element.dataset.field, value } })
    }
  }

  toggleDropdown(event) {
    if (this.canToggle(event)) {
      this.optionsTarget.classList.toggle('is-open')
    }
  }

  selectOption({currentTarget, params}) {
    let { value, dispatch, field } = params
    const { label } = currentTarget.dataset

    if (this.multiple) {
      currentTarget.classList.toggle('is-active')
      this.toggleTag(value, currentTarget.innerHTML)
      value = [{id: value.toString(), name: currentTarget.innerHTML}]
    } else {
      if(isActivated(currentTarget)) {
        desactivate([currentTarget])

        this.selectedTarget.textContent = this.selectedTarget.dataset.placeholder

        if (this.hasClearButtonTarget) hideV2(this.clearButtonTarget)
      } else {
        activate([currentTarget])

        this.selectTarget.value = value
        this.selectedTarget.classList.remove('placeholder')
        this.selectedTarget.textContent = label

        if (this.hasClearButtonTarget) showV2(this.clearButtonTarget)
      }

      this.toggleOptions(value)

      close(this.optionsTarget)
    }

    if (dispatch) { this.dispatch("fieldChanged", {detail: {field, value}}) }
  }

  toggleTag(value, label) {
    const tag = [...this.selectedContainerTarget.children].find((selectedChild) => selectedChild.childNodes[1].dataset.searchableSelectValueParam == value)
    const option = [...this.selectTarget.options].find((option) => option.value == value)

    if(tag) {
      this.selectedContainerTarget.removeChild(tag)
      option.selected = false
    } else {
      const tagTemplate = this.templateTarget.content.cloneNode(true).childNodes[0]
      tagTemplate.childNodes[0].innerHTML = label
      tagTemplate.childNodes[1].dataset.searchableSelectValueParam = value
      this.selectedContainerTarget.appendChild(tagTemplate)
      option.selected = true
    }
  }

  clickOutside() {
    close(this.optionsTarget)
  }


  toggleOptions(value) {
    this.optionTargets.map((option) => {
      if(isActivated(option) && option.dataset.searchableSelectValueParam != value) { desactivate([option]) }
    })
  }

  removeTag({params, currentTarget}) {
    const value = params.value
    currentTarget.parentNode.remove()

    for (const option of this.selectTarget.options) {
      if (option.value == value) { option.selected = false }
    }

    const optionElement = this.optionTargets.find(option => option.dataset.searchableSelectValueParam == value)
    if (optionElement) { desactivate([optionElement]) }

    this.dispatch("clearField", {detail: {field: this.element.dataset.field, value }})
  }

  search(event) {
    const searchText = event.currentTarget.value.toLowerCase()
    const list = this.optionsTarget.querySelectorAll('li:not(.is-hidden), li.searchable')

    if (searchText.length < 2) {
      for (const item of list) {
        if(item.classList.contains("searchable") && isHiddenV2(item)) {
          showV2(item)
          item.classList.remove("searchable")
        }
      }
      return
    }

    for (const item of list) {
      const itemText = item.innerHTML.toLowerCase()
      if (!itemText.includes(searchText) && !isHiddenV2(item)) {
        item.classList.add("searchable")
        hideV2(item)
      } else if(itemText.includes(searchText) && isHiddenV2(item)) {
        showV2(item)
        item.classList.remove("searchable")
      }
    }
  }

  canToggle({target, currentTarget}) {
    if (this.hasClearButtonTarget) {
      return !this.clearButtonTarget.contains(target) && !currentTarget.classList.contains('is-disabled')
    }
    return !currentTarget.classList.contains('is-disabled')
  }

  clearSelect() {
    this.selectedTarget.classList.add('placeholder')
    this.selectedTarget.textContent = this.selectedTarget.dataset.placeholder
    const option = this.optionTargets.find(option => option.classList.contains('is-active'))
    desactivate([option])
    if (this.hasClearButtonTarget) {
      hideV2(this.clearButtonTarget)
    }
  }
}
