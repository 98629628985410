import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2 } from "../utils/display";
import { addHours, setHours, parse, setMinutes } from 'date-fns';

export default class extends Controller {
  static targets = ['heatmap', 'skeleton', 'form', 'dropzone']

  fetchHeatmap({detail}) {
    showV2(this.skeletonTarget)
    hideV2(this.heatmapTarget)
    const { startDate, endDate } = detail
    const url = new URL(this.heatmapTarget.dataset.heatmapUrl)

    url.searchParams.append('start_date', startDate)
    url.searchParams.append('end_date', endDate)

    fetch(url, {headers: {'Content-Type': 'application/json'}})
      .then(response => response.json())
      .then(({data}) => {
        hideV2(this.skeletonTarget)
        showV2(this.heatmapTarget)
        if (document.querySelector('#heatmap')) { this.dispatchUpdate(JSON.parse(data)) }
        if (document.querySelector("#evaluation_date")) {this.updateEvaluationDate(startDate, endDate, 'pando2')}
      })
      .then(() => this.canFetchCo2Result())
      .catch(error => console.warn(error))
    }

  fetchImportData({target}) {
      let formData = new FormData()
      formData.append(target.id, target.files[0])
      let import_url = new URL(this.formTarget.action)
      import_url = new URL(import_url.href.replace(/\/form\/\d+/, '').concat('/import_co2'))
      fetch(import_url, {method: 'PATCH', headers: {'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content}, body: formData})
        .then(response => response.json())
        .then(({data}) => {
          const { startDate, endDate, flash, importSuccess} = JSON.parse(data)
          document.getElementById('flash').innerHTML = flash
          if (importSuccess) {this.dispatchImport()}
          if (startDate && endDate && document.querySelector("#evaluation_date")) {this.updateEvaluationDate(startDate, endDate, 'csv_import')}
        })
        .catch(error => console.warn(error))
  }

  dispatchUpdate(series) {
    const event = new CustomEvent('update-chart', {detail: { series }})
    document.querySelector('#heatmap').dispatchEvent(event)
  }

  dispatchImport() {
    const event = new CustomEvent('import-success')
    document.querySelector('.dropzone-area').querySelector('input').dispatchEvent(event)
  }

  updateEvaluationDate(startDate, endDate, activeTab) {
    const event = new CustomEvent('update-limit', {detail: { startDate, endDate, activeTab }})
    document.querySelector("#evaluation_date").dispatchEvent(event)
  }

  fetchCo2Result() {
    if (this.hasHeatmapTarget || this.hasDropzoneTarget) {
      const activeTabValue = Array.from(document.querySelectorAll('.co2-measures-tab')).find((element) => element.checked === true).value
      if (activeTabValue === 'manual_transcription') {return}
  
      let date = document.querySelector('#evaluation_date')?.value
      const time = document.querySelector('#evaluation_time_slot')?.value
  
      if (date && time) {
        date = parse(date, 'dd/MM/yyyy', new Date())
  
        fetch(this.buildUrl(date, time, activeTabValue), {headers: {'Content-Type': 'application/json'}})
          .then(response => response.json())
          .then(({data}) => this.updateMeasures(JSON.parse(data)))
          .catch(error => console.warn(error))
      }
    }
  }

  canFetchCo2Result() {
    const noResult = document.querySelector('#nb_measurements_used').value == 0
    const datetimeSet = !!document.querySelector('#evaluation_date').value && !!document.querySelector('#evaluation_time_slot').value
    if (noResult && datetimeSet) { this.fetchCo2Result() }
  }

  buildUrl(date, time, activeTabValue) {
    const url = new URL(activeTabValue === 'csv_import' && this.hasDropzoneTarget ? this.dropzoneTarget.dataset.importUrl : this.heatmapTarget.dataset.historyUrl)
    const { startDate, endDate } = this.dates(date, time)

    url.searchParams.append('start_date', startDate)
    url.searchParams.append('end_date', endDate)

    return url
  }

  dates(date, time) {
    const [hour, minutes] = time.split(':').map(Number)
    let startDate = setHours(date, hour)
    startDate = setMinutes(startDate, minutes)
    let endDate = addHours(startDate, 2)

    return {startDate, endDate}
  }

  updateMeasures({measurementsNb, maxValue, minValue, above800, above1500 }) {
    const inputValues = {'#nb_measurements_used': measurementsNb, '#max_value': maxValue, '#min_value': minValue, '#nb_values_above_800ppm': above800, '#nb_values_above_1500ppm': above1500}
    for (const [key, value] of Object.entries(inputValues)) {
      document.querySelector(key).value = value
      document.querySelector(key).style.setProperty('border-color', '#106d10', 'important')
      document.querySelector(key).style.transition = 'border-color .3s ease'
      setTimeout(() => document.querySelector(key).style.removeProperty('border-color'), 1000)
    }
  }
}